import React, { Component, Fragment } from 'react';
import { graphql } from 'gatsby';
import { ThemeProvider } from 'styled-components';
import { ResetCSS } from 'common/source/assets/css/style';
import { theme } from 'common/source/styles/theme';
import { GlobalStyle, ContentWrapper } from 'common/source/styles';
import Navbar from 'common/source/containers/SolarNavbar';
import Banner from 'common/source/containers/Banner';
// import BlogSection from 'common/source/containers/BlogSection';
// import TimelineSection from 'common/source/containers/TimelineSection';
// import ContactSections from 'common/source/containers/Contact';
// import PartnerSection from 'common/source/containers/Partner';
// import FeatureSection from 'common/source/containers/FeatureSection';
import Footer from 'common/source/containers/Footer';
import Container from 'common/source/components/UI/Container';

import SEO from '../components/SEO';
import ResourcesView from '../components/ResourcesView';
import config from '../../data/SiteConfig';

class ResourcesPage extends Component {
  render() {
    const { data } = this.props;
    const { allResources } = data;
    const resources = allResources.edges.map(edge => edge.node);

    return (
      <ThemeProvider theme={theme}>
        <Fragment>
          <SEO pageTitle={`Blog & Recursos | ${config.siteTitle}`} path="/recursos" />
          <ResetCSS />
          <GlobalStyle />

          <ContentWrapper>
            <Navbar />
            <Banner heading="Blog & Recursos" background="blue" />
            <Container>
              <ResourcesView resources={resources} />
            </Container>
            <Footer />
          </ContentWrapper>
        </Fragment>
      </ThemeProvider>
    );
  }
}

export default ResourcesPage;

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query ResourcesPosts {
    allResources: allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "RESOURCE" } } }
      sort: { order: ASC, fields: [frontmatter___featured] }
    ) {
      edges {
        node {
          excerpt
          timeToRead
          frontmatter {
            slug
            title
            type
            featured
            category
            cover
            thumbnail
            tags
          }
        }
      }
    }
  }
`;
