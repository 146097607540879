import React, { Fragment } from 'react';
import { Link } from 'gatsby';
import Image from 'reusecore/src/elements/Image';
import Container from '../../components/UI/Container';
import BlogListWrapper, {
  GridContainer,
  BlogPostWrapper
} from './blog-list.style';

const BlogList = ({ posts, prefix }) => {
  let featured;
  let restOfPosts;
  featured = posts.find(
    post => post.frontmatter.slug === 'feria-jardinera-vd-2019'
  );
  restOfPosts = posts.filter(post => post !== featured);
  if (!featured) {
    const [first, ...rest] = posts;
    featured = first;
    restOfPosts = rest;
  }
  return (
    <BlogListWrapper>
      <Container>
        <GridContainer template="auto">
          <BlogPostWrapper featured>
            <div className="resource-img">
              <Image
                src={
                  featured.frontmatter.thumbnail || featured.frontmatter.cover
                }
                alt={`UpSolar ${featured.frontmatter.title}`}
              />
            </div>
            <div className="resource-labels">
              {featured.frontmatter.tags.map((tag, index) => {
                const isLast = index === featured.frontmatter.tags.length - 1;
                return (
                  <Fragment key={`${featured.frontmatter.slug}-${tag}`}>
                    <span>{tag}</span>
                    {!isLast && <span className="divider">|</span>}
                  </Fragment>
                );
              })}
            </div>
            <div className="resource-info">
              <Link to={`/${prefix || 'blog'}/${featured.frontmatter.slug}`}>
                <h2>{featured.frontmatter.title}</h2>
              </Link>
              <p>{featured.excerpt}</p>
            </div>
          </BlogPostWrapper>
        </GridContainer>
        <GridContainer>
          {restOfPosts.map(post => {
            const {
              excerpt,
              frontmatter: { title, slug, tags }
            } = post;
            return (
              <BlogPostWrapper key={`post-${slug}`}>
                <div className="resource-labels">
                  {tags.map((tag, index) => {
                    const isLast = index === tags.length - 1;
                    return (
                      <Fragment key={`${slug}-${tag}`}>
                        <span>{tag}</span>
                        {!isLast && <span className="divider">|</span>}
                      </Fragment>
                    );
                  })}
                </div>
                <div className="resource-info">
                  <Link to={`/${prefix || 'blog'}/${slug}`}>
                    <h3>{title}</h3>
                  </Link>
                  <p>{excerpt}</p>
                </div>
              </BlogPostWrapper>
            );
          })}
        </GridContainer>
      </Container>
    </BlogListWrapper>
  );
};

export default BlogList;
