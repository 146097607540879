import React from 'react';
import BlogList from 'common/source/containers/BlogList';
import ResourcesViewWrapper from './resources.style';

const ResourcesView = ({ resources }) => {
  return (
    <ResourcesViewWrapper>
      <BlogList posts={resources} prefix="recursos" />
    </ResourcesViewWrapper>
  );
};

export default ResourcesView;
